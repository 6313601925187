import en from './en';
import es from './es';

export const locales = ['en', 'es'];

export const messages = {
  en,
  es,
};

export default {
  locales,
  messages,
};
