<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      class="lighten-6"
      elevate-on-scroll
      height="80"
    >
      <router-link
        tag="a"
        :to="'/' + $i18n.locale"
        class="d-flex align-center text-decoration-none"
      >
        <img
          src="./assets/logo-bitcoinpoint-black.svg"
          height="50"
          class="mr-2 d-inline-block"
        />
        <span
          class="
            white--text
            rounded-xl
            px-2
            primary
            text-h7
            ml-2
            font-weight-medium
            text-uppercase
          "
        >
          {{ $t("blog") }}
        </span>
      </router-link>

      <v-spacer></v-spacer>
      <v-sheet
        color="transparent"
        max-width="400"
        width="100%"
        v-if="$i18n.locale === 'tmp'"
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="400"
          :max-width="400"
          offset-y
          :nudge-top="0"
          flat
          content-class="elevation-1"
        >
          <template v-slot:activator="{ attrs }">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              hide-details
              flat
              dark
              outlined
              clearable
              full-width
              v-bind="attrs"
              :placeholder="$t('search')"
            >
            </v-text-field>
          </template>
          <v-card max-width="400" flat>
            <v-list v-if="items">
              <v-list-item
                :key="item.uid"
                v-for="item in items"
                link
                @click="gotoArticle(item.uid)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="subtitle-1 font-weight-bold"
                    v-text="item.data.title"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item._highlightResult.body.value"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-pagination
              v-if="totalPages > 1"
              v-model="page"
              :length="totalPages"
              :total-visible="5"
            ></v-pagination>
          </v-card>
        </v-menu>
      </v-sheet>
      <v-btn
        depressed
        flat
        dark
        outlined
        rounded
        color="primary"
        :href="
          $i18n.locale == 'es'
            ? 'https://compra-bitcoin-marbella.bitcoinpoint.shop/'
            : 'https://buy-bitcoin-marbella.bitcoinpoint.shop/'
        "
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        Bitcoin ATM
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            :href="'/' + ($i18n.locale == 'en' ? 'es' : 'en')"
            v-on="on"
            class="mx-4"
          >
            <img src="./assets/languages-icon.svg" width="40" />
          </v-btn>
        </template>
        <span>{{ $t("change_language") }}</span>
      </v-tooltip>
    </v-app-bar>
    <router-view :topics="topics"></router-view>
    <v-footer class="black">
      <v-container class="text-center white--text pt-10 pb-16">
        &copy;{{ new Date().getFullYear() }} Bitcoin Point
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import algoliasearch from "algoliasearch/lite";

const client = algoliasearch("", "");
const index = client.initIndex("blog");

import { getTopicsPath, getArticlesPath } from "./router/index";

export default {
  name: "App",
  data: () => ({
    topicsPath: getTopicsPath(),
    articlesPath: getArticlesPath(),
    isLoading: false,
    topics: [],
    items: null,
    search: null,
    menu: false,
    page: 1,
    perPage: null,
    totalPages: null,
    totalResults: null,
  }),
  watch: {
    "$i18n.locale"() {
      this.getTopics();
    },
    page() {
      this.searchRequest();
    },
    search(val) {
      this.page = 1;
      if (val && val.length >= 3) {
        this.searchRequest();
      }
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale === "es" ? "es-es" : "en-us";
    },
  },
  methods: {
    searchRequest() {
      this.isLoading = true;
      this.menu = true;
      index
        .search(this.search, {
          page: this.page - 1,
        })
        .then(({ hits, nbPages, nbHits, hitsPerPage }) => {
          this.items = hits;
          this.totalPages = nbPages;
          this.perPage = hitsPerPage;
          this.totalResults = nbHits;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    gotoArticle(slug) {
      if (slug) {
        this.search = null;
        this.menu = false;
        this.$router.push(this.articlesPath + slug);
      }
    },
    changeLanguage() {
      if (this.$i18n.locale === "es") {
        this.$router.push("/en");
        this.$i18n.locale = "en";
      } else {
        this.$router.push("/en");
        this.$i18n.locale = "en";
      }
    },
    async getTopics() {
      const document = await this.$prismic.client.getSingle("categories", {
        lang: this.locale,
      });
      try {
        this.topics = document.data.categories;
      } catch (e) {
        this.topics = [];
      }
    },
  },
  created() {
    //this.getTopics();
  },
};
</script>

<style>
body {
  font-family: "Montserrat";
}
li > * {
  vertical-align: text-top;
}
.v-application a {
  color: #1565c0 !important;
}
</style>